import React, { Component } from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'

class PageTemplate extends Component {
  render() {
    const currentPage = this.props.data.wordpressPage

    return (
      <Layout>
        <SEO
          title={currentPage.title}
          description={currentPage.excerpt}
          url={
            this.props.data.site.siteMetadata.siteUrl +
            '/page/' +
            currentPage.slug +
            '/'
          }
        />
        <article className={'page'}>
          <header className={'entry-header'}>
            <h1
              dangerouslySetInnerHTML={{ __html: currentPage.title }}
              className={'entry-title'}
            />
          </header>

          <div
            dangerouslySetInnerHTML={{ __html: currentPage.content }}
            className={'entry-content'}
          />
        </article>
      </Layout>
    )
  }
}

export default PageTemplate

export const pageQuery = graphql`
  query currentPageQuery($id: Int!) {
    wordpressPage(wordpress_id: { eq: $id }) {
      title
      excerpt
      content
      slug
      date(formatString: "MMMM DD, YYYY")
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`
